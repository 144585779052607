.loader-wrapper {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: #2484c6;
  z-index: 15;
}

.loader {
  position: relative;
  width: 320px;
  height: 100px;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.loader-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 50%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background-color: #2484c6;
  animation: loader-animation 1s ease-in-out infinite;
  text-align: center;
}

@keyframes loader-animation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.loader-wrapper {
  background-color:  #2484c6;
}
